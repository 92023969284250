import whatsappIcon from '../../assets/whatsapp-icon.svg';

const WhatsappChat = () => {
  return (
    <div className='whatsapp-chat'>
      <a href={`whatsapp://send?text=Hello, my name is &phone=+18402139875`}>
        <img src={whatsappIcon} alt='whatsapp' />
      </a>
    </div>
  );
};
export default WhatsappChat;
