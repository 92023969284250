import React from 'react';
import { AiFillHome } from 'react-icons/ai';
import { FaUserAlt } from 'react-icons/fa';
import { GiPayMoney,  } from 'react-icons/gi';
import { RiStockFill } from 'react-icons/ri';
import { Link } from 'react-router-dom';

const BottomNav = () => {
  return (
    <div className='bottom-nav'>
      <Link to='/dashboard' className='item'>
        <AiFillHome />
        <p>Home</p>
      </Link>
      <Link to='/dashboard/account' className='item'>
        <FaUserAlt />
        <p>Profile</p>
      </Link>
      <Link to='/dashboard/deposit' className='item'>
        <GiPayMoney />
        <p>Deposit</p>
      </Link>
      <Link to='/dashboard/spot-trading' className='item'>
        <RiStockFill />
        <p>Spot Trading</p>
      </Link>
    </div>
  );
};

export default BottomNav;
