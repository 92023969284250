import { useGlobalContext } from '../../context/context';
import WithdrawalRequest from '../modals/WithdrawalRequest';
import { useState } from 'react';
import Loader from '../loaders/Loader';
import BankWithdrawalRequest from '../modals/BankWithdrawalRequest';

export const BankWithdrawal = () => {
  const { userDetails, isNavbar } = useGlobalContext();
  const [loading, setLoading] = useState(false);
  const [withdrawalDetails, setWithdrawalDetails] = useState({
    amount: ``,
    asset: ``,
    email: ``,
    exchange: ``,
    walletAddress: ``,
  });

  const [showModal, setShowModal] = useState(false);

  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
      setShowModal(true);
    }, 3000);
  };

  const handleChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    setWithdrawalDetails({
      ...withdrawalDetails,
      [name]: value,
    });
  };

  return (
    <article className='bank-withdrawal'>
      <h2>Bank Transfer Withdrawal Request</h2>
      <form onSubmit={handleSubmit}>
        {/* ACCOUNT NUMBER */}
        <div className='form-control'>
          <label
            htmlFor='acc-no'
            className={isNavbar ? `navOpen label` : `label`}
          >
            Account Number
          </label>{' '}
          <br />
          <input
            type='text'
            placeholder='Account Number'
            id='walletAddress'
            className='input'
            name='walletAddress'
            required
            value={withdrawalDetails.walletAddress}
            onChange={handleChange}
          />
        </div>

        {/* BANK SWIFT CODE */}
        <div className='form-control'>
          <label
            htmlFor='swift-code'
            className={isNavbar ? `navOpen label` : `label`}
          >
            Bank Swift Code
          </label>
          <input
            type='text'
            placeholder='Bank Swift Code'
            id='asset'
            className='input'
            name='asset'
            required
            value={withdrawalDetails.asset}
            onChange={handleChange}
          />
        </div>

        {/* BANK NAME */}
        <div className='form-control'>
          <label
            htmlFor='bank-name'
            className={isNavbar ? `navOpen label` : `label`}
          >
            Bank Name
          </label>
          <input
            type='text'
            placeholder='Bank Name'
            id='exchange'
            className='input'
            name='exchange'
            value={withdrawalDetails.exchange}
            onChange={handleChange}
            required
          />
        </div>

        {/* EMAIL */}
        <div className='form-control'>
          <label
            htmlFor='email'
            className={isNavbar ? `navOpen label` : `label`}
          >
            Email
          </label>{' '}
          <br />
          <input
            type='email'
            placeholder='Email Address'
            id='email'
            className='input'
            name='email'
            required
            onChange={handleChange}
            value={withdrawalDetails.email}
          />
        </div>

        {/* AMOUNT */}
        <div className='form-control'>
          <label
            htmlFor='amount'
            className={isNavbar ? `navOpen label` : `label`}
          >
            Amount
          </label>
          <input
            type='number'
            placeholder='Amount'
            id='amount'
            className='input'
            name='amount'
            required
            value={withdrawalDetails.amount}
            onChange={handleChange}
          />
        </div>
        {withdrawalDetails.amount > userDetails.tradeProfit && (
          <p>Withdrawal amount must not exceed total profits</p>
        )}
        <div className='withdraw-btn'>
          <button
            className='gold'
            type='submit'
            disabled={!userDetails.userCanWithdraw && true}
          >
            Make Withdrawal Request
          </button>
        </div>
      </form>
      {loading && (
        <section className='fullscreen-loader'>
          <Loader />
        </section>
      )}
      {showModal && (
        <BankWithdrawalRequest
          data={withdrawalDetails}
          setShowModal={setShowModal}
        />
      )}
    </article>
  );
};

export const CryptoWithdrawal = () => {
  const { showModal, setShowModal, userDetails, isNavbar } = useGlobalContext();
  const [loading, setLoading] = useState(false);
  const [showWithdrawalMsg, setShowWithdrawalMsg] = useState(false);

  const [withdrawalDetails, setWithdrawalDetails] = useState({
    amount: ``,
    asset: ``,
    email: ``,
    exchange: ``,
    walletAddress: ``,
  });

  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
      setShowModal(true);
    }, 3000);
  };

  const handleChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    setWithdrawalDetails({
      ...withdrawalDetails,
      [name]: value,
    });
  };

  return (
    <article className='crypto-withdrawal'>
      <h2>Crypto Withdrawal Request</h2>
      <form onSubmit={handleSubmit}>
        {/* AMOUNT */}
        <div className='form-control'>
          <label
            htmlFor='amount'
            className={isNavbar ? `navOpen label` : `label`}
          >
            Amount
          </label>{' '}
          <br />
          <input
            type='number'
            placeholder='Amount'
            id='amount'
            className='input'
            name='amount'
            value={withdrawalDetails.amount}
            onChange={handleChange}
            required
            onClick={() => setShowWithdrawalMsg(true)}
          />
        </div>

        {/* WALLET ADDRESS */}
        <div className='form-control'>
          <label
            htmlFor='walletAddress'
            className={isNavbar ? `navOpen label` : `label`}
          >
            Wallet Address
          </label>{' '}
          <br />
          <input
            type='text'
            placeholder='Wallet Address'
            id='walletAddress'
            className='input'
            name='walletAddress'
            required
            value={withdrawalDetails.walletAddress}
            onChange={handleChange}
          />
        </div>

        {/* EMAIL */}
        <div className='form-control'>
          <label
            htmlFor='email'
            className={isNavbar ? `navOpen label` : `label`}
          >
            Email
          </label>{' '}
          <br />
          <input
            type='email'
            placeholder='Email Address'
            id='email'
            className='input'
            name='email'
            required
            onChange={handleChange}
            value={withdrawalDetails.email}
          />
        </div>

        {/* EXCHANGE */}
        <div className='form-control'>
          <label
            htmlFor='exchange'
            className={isNavbar ? `navOpen label` : `label`}
          >
            Crypto Exchange
          </label>
          <br />
          <input
            type='text'
            placeholder='Eg. Binance'
            id='exchange'
            className='input'
            name='exchange'
            required
            value={withdrawalDetails.exchange}
            onChange={handleChange}
          />
        </div>

        {/* ASSET */}
        <div className='form-control'>
          <label
            htmlFor='asset'
            className={isNavbar ? `navOpen label` : `label`}
          >
            Asset
          </label>
          <br />
          <input
            type='text'
            placeholder='Eg. Bitcoin, Ethereum...'
            id='asset'
            className='input'
            name='asset'
            required
            value={withdrawalDetails.asset}
            onChange={handleChange}
          />
        </div>
        {withdrawalDetails.amount > userDetails.tradeProfit && (
          <p>Withdrawal amount must not exceed total profits</p>
        )}
        {!userDetails.userCanWithdraw && showWithdrawalMsg ? (
          <p>Your profit has not reached the minimum withdrawal threshold</p>
        ) : null}

        <div className='withdraw-btn'>
          <button
            disabled={!userDetails.userCanWithdraw && true}
            className='gold'
            type='submit'
            onClick={(e) => {
              if (
                e.currentTarget.parentElement.previousElementSibling.childNodes[0].classList.contains(
                  `red`
                )
              ) {
                e.target.setAttribute('disabled', true);
              }
            }}
          >
            Make Withdrawal Request
          </button>
        </div>
      </form>
      {loading && (
        <section className='fullscreen-loader'>
          <Loader />
        </section>
      )}
      {showModal && <WithdrawalRequest data={withdrawalDetails} />}
    </article>
  );
};
