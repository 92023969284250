import {
  TickerTape,
  MiniChart,
  AdvancedRealTimeChart,
  TechnicalAnalysis,
} from "react-ts-tradingview-widgets";

export const Ticker = () => {
  return <TickerTape colorTheme='dark'></TickerTape>;
};

export const MiniChartBTC = () => {
  return (
    <MiniChart
      colorTheme='dark'
      width='100%'
      symbol='BITSTAMP:BTCUSD'
    ></MiniChart>
  );
};

export const MiniChartETH = () => {
  return (
    <MiniChart
      colorTheme='dark'
      width='100%'
      symbol='BITSTAMP:ETHUSD'
    ></MiniChart>
  );
};

export const MiniChartLTC = () => {
  return (
    <MiniChart
      colorTheme='dark'
      width='100%'
      symbol='BINANCE:LTCUSDT'
    ></MiniChart>
  );
};

export const AdvancedChart = () => {
  return (
    <AdvancedRealTimeChart
      theme='dark'
      autosize
      symbol='BITSTAMP:BTCUSD'
    ></AdvancedRealTimeChart>
  );
};

export const Technical = () => {
  return (
    <TechnicalAnalysis
      colorTheme='dark'
      width='100%'
      symbol='BITSTAMP:BTCUSD'
    ></TechnicalAnalysis>
  );
};
