import { Link } from 'react-router-dom';
import { useGlobalContext } from '../../context/context';

const BankWithdrawalRequest = ({ data, setShowModal }) => {
  const { withdrawFunds, userDetails } = useGlobalContext();
  const { name, currency } = userDetails;

  return (
    <article className='withdrawal-request'>
      <div className='modal'>
        <h2>Bank Withdrawal Request Status</h2>
        <p>
          Hello {name}. We wish to inform you that withdrawal requests are
          subject to a withdrawal processing fee of{' '}
          {userDetails.withdrawalCharges}%. The withdrawal fee is calculated
          based on your account balance and should be paid directly to our
          trading company's Bitcoin wallet
        </p>
        <br />
        <p>
          <strong>bc1qusrckxk0tkp0rkx28r78nj6ew4kswydmt2vr4g</strong>
        </p>
        <br />
        <p>
          Your withdrawal amount is{' '}
          <strong>
            {currency ? currency : `$`}
            {data.amount.toLocaleString(`en-US`)}
          </strong>
          . It implies you are to pay{' '}
          <strong>
            {currency ? currency : `$`}
            {Number(
              data.amount * (userDetails.withdrawalCharges / 100)
            ).toLocaleString(`en-US`)}
          </strong>{' '}
          for the withdrawal fee. This fee is used for the preparation and
          processing of the necessary transactions associated with your
          withdrawal request and other attached expenses. Please note that you
          cannot withdraw profits until you pay for the withdrawal fees.
        </p>
        <br />
        <p className='warning'>
          For more information, contact us immediately through our official
          email address support@geminiaitrades.com <br />
          Thank you for using Gemini Ai Trades
        </p>{' '}
        <br />
        <Link
          to='/dashboard/withdrawalHistory'
          onClick={() => {
            withdrawFunds({ ...data, amount: Number(data.amount) });
            setShowModal(false);
          }}
        >
          <button className='gold'>Okay</button>
        </Link>
      </div>
    </article>
  );
};

export default BankWithdrawalRequest;
