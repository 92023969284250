import { useEffect } from 'react';
import { useGlobalContext } from '../../context/context';
import aboutHeaderImg from '../../assets/about-header.png';
import { aboutInfo } from '../../data/data';

const About = () => {
  const { resetPage } = useGlobalContext();

  useEffect(() => {
    resetPage(`About Us`);
    // eslint-disable-next-line
  }, []);

  return (
    <>
      <section className='about'>
        <div className='header'>
          <div className='top'>
            <div className='left'>
              <h1>Welcome to Gemini Ai Trades</h1>
              <p>
                At Gemini Ai Trades, we believe that everyone should have the
                freedom to earn, hold, spend, share and give their money - no
                matter who you are or where you come from.
              </p>
            </div>
            <div className='right'>
              <img src={aboutHeaderImg} alt='about us' />
            </div>
          </div>
          <div className='bottom'>
            {aboutInfo.map((info) => {
              const { stat, text, icon } = info;
              return (
                <div className='info'>
                  <div className='img'>
                    <img src={icon} alt={stat} />
                  </div>
                  <div className='stat'>
                    <h4>{stat}</h4>
                    <p>{text}</p>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
        <div className='mission'>
          <h2>Our Mission</h2>
          <p>
            Our mission is to accelerate the adoption of cryptocurrency so that
            you and the rest of the world can achieve financial freedom and
            inclusion.
          </p>
        </div>
        <div className='who-are-we'>
          <h2>Who we are</h2>
          <p>
            Recognizing the importance of Bitcoin from the onset, and
            understanding that the exchange is the most critical part of the
            cryptocurrency ecosystem, Jesse Powell founded Gemini Ai Trades to
            give people the means to quickly and securely invest in the space.
            Since then, the Gemini Ai Trades has grown by leaps and bounds with
            hundreds of employees spanning the globe. We're a diverse group of
            thinkers and doers that are dedicated to making cryptocurrency
            available and accessible to the world and enabling people from all
            walks of life to invest in their independence.
          </p>
        </div>
        <div className='what-we-do'>
          <h2>What we do</h2>
          <br />
          <h4>Empowering investors</h4>
          <p>
            Whether you're an advanced trader or a crypto-beginner, Fidelity
            Trades gives you the power to chart your own financial course. Our
            exchange has an ever-growing number of cryptocurrency pairs for you
            to invest in and a slew of tools and features for you to leverage as
            you grow your portfolio.
          </p>
          <br />
          <br />
          <h4>Supporting institutions</h4>
          <p>
            From over-the-counter trading to personalized white-glove account
            management, Gemini Ai Trades is the premier cryptocurrency investing
            solution for institutions of all sizes. We offer exceptional
            liquidity and competitive pricing for all our markets so you can
            achieve your investment goals quickly and confidently.
          </p>
        </div>
      </section>
    </>
  );
};

export default About;
