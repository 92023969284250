import { Link } from "react-router-dom";
import { useGlobalContext } from "../../context/context";
import Copy from "../../components/Copy/Copy";

import CheckMark from "../../assets/check.svg";

const SuccessfulRegistration = () => {
  const { seedPhrase, setShowModal } = useGlobalContext();

  return (
    <article className='successful-registration'>
      <div className='modal'>
        <div className='img'>
          <img src={CheckMark} alt='success' />
        </div>
        <h2>Successful Registration</h2>
        <p className='warning'>
          Please, copy out your seed phrase (This can be used to reset your
          password !)
        </p>
        <div className='seed-phrase'>
          <p>{seedPhrase}</p>
          <Copy target={`.seed-phrase p`} />
        </div>
        <Link to='/sign-in' onClick={() => setShowModal(false)}>
          <button className='gold'>Login</button>
        </Link>
      </div>
    </article>
  );
};

export default SuccessfulRegistration;
